<template>
  <v-card
    max-width="600"
    class="mx-auto my-16"
    color="cyan"
    id="book-list"
    href="#get-app"
  >
    <v-card-title class="justify-center pb-0">
      <v-icon x-large>mdi-book-open-outline</v-icon>
    </v-card-title>
    <v-card-title class="justify-center pt-0">Books</v-card-title>
    <v-img
      :src="require('../assets/page000-500x354.jpg')"
      class="mx-auto"
      contain
      max-width="500"
      transition="scale-transition"
    />
    <v-card-title>Oink-Oink and the SNARCHELQUANT</v-card-title>
    <v-card-text
      ><p>
        Join the pig Oink-Oink on an adventure in search of a rare creature.
        Oink-Oink travels through colorful and imaginative worlds. Meet all
        sorts of exciting creatures and let the child join in and fantasize! An
        engaging tale, created by educators and artists. Stimulates the child's
        imagination and language development.
      </p>
      <p>
        <strong
          >This book is available in our app. Click here to get it!</strong
        >
      </p>
      <LangAvailableApp />
    </v-card-text>
  </v-card>
</template>

<script>
import LangAvailableApp from "./LangAvailableApp";

export default {
  name: "BookList",
  components: {
    LangAvailableApp,
  },
};
</script>
