<template>
  <v-app-bar app id="navbar">
    <div class="d-flex align-center">
      <v-img alt="OinkWeb Logo" class="shrink mr-2" contain :src="require('../assets/ui/android-chrome-192x192.png')"
        style="cursor: pointer" transition="scale-transition" width="40" @click="
          window().scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        " />
    </div>

    <div class="d-none d-md-block" style="white-space: nowrap">
      <v-btn color="orange" tile href="#get-app" class="ml-2">
        App
        <v-icon class="ml-2">mdi-tablet-cellphone</v-icon>
      </v-btn>

      <v-btn color="purple accent-1" tile href="#get-newsletter" class="ml-2">
        Newsletter
        <v-icon class="ml-2">mdi-email-outline</v-icon>
      </v-btn>

      <v-btn color="lime" tile href="#milestones" class="ml-2">
        Contribute
        <v-icon class="ml-2">mdi-hand-heart-outline</v-icon>
      </v-btn>

      <v-btn color="cyan" tile href="#book-list" class="ml-2">
        Books
        <v-icon class="ml-2">mdi-book-open-outline</v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>

    <v-btn v-if="false" text href="">
      <span class="mr-2">Lang</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn text class="mr-2" @click="toggleLang">
      {{ language }} <span class="fi ml-2" :class="'fi-' + flag"></span></v-btn>

    <v-btn text v-if="!isProd">
      <a href="https://animate.style/" target="_blank" class="mr-2">Animations</a>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn text v-if="!isProd">
      <a href="https://materialdesignicons.com/" target="_blank" class="mr-2">Icons</a>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn text v-if="!isProd">
      <a href="https://oinkweb.com/" target="_blank" class="mr-2">OinkWeb.com</a>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn icon color="#3b5998" href="https://www.facebook.com/OinkWe/reviews/" target="_blank">
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn icon color="#405DE6" href="https://instagram.com/oinkweb" target="_blank">
      <v-icon>mdi-instagram</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isProd: process.env.NODE_ENV === "production",
      lang: localStorage.getItem("lang"),
    };
  },

  computed: {
    flag() {
      return this.lang == "sv" ? "us" : "se";
    },
    language() {
      return this.lang == "sv" ? "English" : "Svenska";
    },
  },
  methods: {
    window() {
      return window;
    },

    toggleLang() {
      this.lang = localStorage.getItem("lang") == "sv" ? "en" : "sv";
      localStorage.setItem("lang", this.lang);
      window.location.reload();
    },
  },
};
</script>
