<template>
  <v-app>
    <NavBar />

    <v-main>
      <MastHead />
      <AppGet ref="appget" />
      <NewsletterSignup />
      <MilestonesList />
      <BookList />
      <PrivacyPolicy />
      <FooterComponent />
    </v-main>
    <script type="application/javascript" defer src="js/snippets.js"></script>

    <!-- unity auto generated-->
    <link rel="stylesheet" href="TemplateData/style.css" />

    <!-- apply animations to v-if? https://stackoverflow.com/questions/44794501/vuejs-animate-css-not-working -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.4.3/css/flag-icons.min.css"
      integrity="sha512-uvXdJud8WaOlQFjlz9B15Yy2Au/bMAvz79F7Xa6OakCl2jvQPdHD0hb3dEqZRdSwG4/sknePXlE7GiarwA/9Wg=="
      crossorigin="anonymous" referrerpolicy="no-referrer" />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import MastHead from "./components/MastHead";
import AppGet from "./components/AppGet";
import NewsletterSignup from "./components/NewsletterSignup";
import MilestonesList from "./components/MilestonesList";
import BookList from "./components/BookList";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FooterComponent from "./components/FooterComponent";

export default {
  name: "App",

  components: {
    NavBar,
    MastHead,
    AppGet,
    NewsletterSignup,
    MilestonesList,
    BookList,
    PrivacyPolicy,
    FooterComponent,
  },

  beforeCreate: () => {
    window.lang = "en";
  },
  created: () => { },
  mounted() {
    window.globalVueRefAppGet = this.$refs.appget;
    //console.log("vue app mounted", this.$refs.appget, window.globalVueRefAppGet);
  }
};
</script>

<style>
/*
@media only screen and (max-width: 959px) {
  #app {
    background-color: cyan;
  }
}
*/

@media only screen and (min-width: 960px) {
  #app {
    /*background: url("assets/homeforest.png") no-repeat center center fixed !important;*/
    /*background-size: cover;*/
    background: linear-gradient(0deg,
        rgba(0, 124, 191, 1) 0%,
        rgba(0, 255, 255, 1) 62%,
        rgba(255, 255, 255, 1) 100%);
  }
}
</style>