<template>
  <v-container>
    <v-row class="text-center" id="mast-head-image">
      <v-col cols="12">
        <v-img
          :src="require('../assets/ui/oinkweb-text-logo-700x167.png')"
          class="mt-16 mx-auto"
          contain
          max-width="700"
          transition="scale-transition"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="mb-3 text-h5">
          {{ t[lang].magicalInteractiveHeader }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// https://animate.style/
export default {
  name: "MastHead",
  data() {
    return {
      lang: localStorage.getItem("lang"),
      t: {
        en: {
          magicalInteractiveHeader:
            "A magical interactive story moment with your child",
        },
        sv: {
          magicalInteractiveHeader:
            "En magisk, interaktiv sagostund med ditt barn",
        },
      },
    };
  },
};
</script>

<style>
@media only screen and (max-width: 959px) {
  #mast-head-image {
    background: linear-gradient(
      180deg,
      rgba(0, 124, 191, 1) 0%,
      rgba(0, 255, 255, 1) 62%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

/*
@media only screen and (min-width: 960px) {
  #mast-head-image {
  }
}
*/
</style>