<template>
  <v-card
    max-width="600"
    class="mx-auto my-16 ml-onclick-form"
    color="purple accent-1"
    id="get-newsletter"
    onclick="ml('show', 'n2q7FG', true);"
    style="cursor: pointer"
  >
    <v-card-title class="justify-center pb-0">
      <v-icon x-large>mdi-email-outline</v-icon>
    </v-card-title>
    <v-card-title class="justify-center pt-0">Newsletter</v-card-title>
    <v-btn
      color="purple accent-1"
      block
      dark
      onclick="ml('show', 'n2q7FG', true);"
      >Sign Up</v-btn
    >
  </v-card>
</template>

<script>
export default {
  name: "NewsletterSignup",
};
</script>

<script>
export default {};
</script>