<template>
  <v-list subheader color="transparent">
    <v-divider></v-divider>
    <v-subheader>Available languages</v-subheader>
    <v-list-item v-for="lang in [
      {
        text: 'English',
        flag: 'us',
      },
      {
        text: 'Svenska (Swedish)',
        flag: 'se',
      },
    ]" :key="lang.text">
      <v-list-item-icon>
        <span class="fi" :class="'fi-' + lang.flag"></span>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ lang.text }} </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
// https://www.growthbunker.dev/vueflags/?ref=madewithvuejs.com
export default {
  name: "LangAvailableApp",
  data() {
    return {};
  },
};
</script>
