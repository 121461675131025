<template>
  <v-card max-width="600" class="mx-auto" color="lime" id="milestones">
    <v-card-title class="justify-center pb-0">
      <v-icon x-large>mdi-flag-triangle</v-icon>
    </v-card-title>
    <v-card-title class="justify-center pt-0"
      >Roadmap &amp; Milestones</v-card-title
    >
    <v-card-text>
      Here you can track and contribute to our progress! OinkWeb is a crowd
      funded and crowd sourced project.
    </v-card-text>

    <v-list
      subheader
      one-line
      light
      style="background-repeat: no-repeat; background-position: 420px 15px"
      :style="{
        backgroundImage: 'url(' + require('@/assets/homeforest-tree.png') + ')',
      }"
    >
      <v-subheader inset
        >Completed Milestones <v-icon>mdi-flag-triangle</v-icon></v-subheader
      >
      <v-list-item
        v-for="completed in [
          'Write our first book',
          'Read to our loved ones',
          'Create an app and website for our books',
        ]"
        :key="completed"
      >
        <v-list-item-avatar>
          <v-icon color="green">mdi-check-bold</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="completed"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card-title class="justify-center pb-0">
      <v-icon large>mdi-hand-heart-outline</v-icon>
    </v-card-title>
    <v-card-title class="justify-center">Help Us</v-card-title>
    <v-card-text class="text-center">
      <strong>Please comment, share and like us on social media!</strong>
    </v-card-text>
    <v-list subheader light>
      <v-divider inset></v-divider>
      <v-subheader inset
        >Social Media Milestones
        <v-icon class="ml-2">mdi-comment-text-multiple</v-icon></v-subheader
      >
      <v-list-item
        v-for="milestone in [
          {
            text: 'Click here to write a review, to help us get 20 great reviews ',
            href: 'https://play.google.com/store/apps/details?id=com.WebbTechSweden.OinkWeb',
            strongText: 'on Google Play',
            icon: 'mdi-google-play',
            target: '_blank',
          },
          {
            text: 'Click here to write a review, to help us get 20 great reviews on ',
            href: 'https://www.facebook.com/OinkWe/reviews/',
            strongText: 'our Facebook page',
            icon: 'mdi-facebook',
            target: '_blank',
          },
          {
            text: 'Click here to sign up to our newsletter, to help us get 100 subscribers on',
            href: '#get-newsletter',
            strongText: 'our mailing list',
            icon: 'mdi-email-outline',
          },
        ]"
        :key="milestone.text"
        :href="milestone.href"
        :target="milestone.target"
      >
        <v-list-item-icon>
          <v-icon>mdi-flag-triangle</v-icon>
        </v-list-item-icon>

        <v-list-item-content href="milestone.href">
          <v-list-item-title
            >{{ milestone.text }}
            <strong>{{ milestone.strongText }}</strong></v-list-item-title
          >
        </v-list-item-content>
        <v-icon>{{ milestone.icon }}</v-icon>
      </v-list-item>

      <v-divider inset></v-divider>
      <v-subheader inset
        >App &amp; Books Roadmap
        <v-icon class="ml-2">mdi-road</v-icon></v-subheader
      >
      <v-list-item
        v-for="milestone in [
          { text: 'Write the first book series' },
          { text: 'Make the books available for printing' },
          { text: 'Make the app read the books out loud' },
          { text: 'Translate the books to many languages' },
        ]"
        :key="milestone.text"
      >
        <v-list-item-avatar>
          <v-icon>mdi-flag-triangle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="milestone.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {};
</script>