<template>
  <v-card max-width="600" class="mx-auto" id="app-privacy-policy">
    <v-card-title class="justify-center pb-0">
      <v-icon x-large>mdi-shield-check</v-icon>
    </v-card-title>
    <v-card-title class="justify-center pt-0">Privacy Policy</v-card-title>

    <v-card-text>
      <p class="text-h6">2022-05-14</p>
      We do not store any personal data in the app at this moment.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
