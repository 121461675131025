var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"600","color":"lime","id":"milestones"}},[_c('v-card-title',{staticClass:"justify-center pb-0"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-flag-triangle")])],1),_c('v-card-title',{staticClass:"justify-center pt-0"},[_vm._v("Roadmap & Milestones")]),_c('v-card-text',[_vm._v(" Here you can track and contribute to our progress! OinkWeb is a crowd funded and crowd sourced project. ")]),_c('v-list',{staticStyle:{"background-repeat":"no-repeat","background-position":"420px 15px"},style:({
      backgroundImage: 'url(' + require('@/assets/homeforest-tree.png') + ')',
    }),attrs:{"subheader":"","one-line":"","light":""}},[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Completed Milestones "),_c('v-icon',[_vm._v("mdi-flag-triangle")])],1),_vm._l(([
        'Write our first book',
        'Read to our loved ones',
        'Create an app and website for our books' ]),function(completed){return _c('v-list-item',{key:completed},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(completed)}})],1)],1)})],2),_c('v-card-title',{staticClass:"justify-center pb-0"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-hand-heart-outline")])],1),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Help Us")]),_c('v-card-text',{staticClass:"text-center"},[_c('strong',[_vm._v("Please comment, share and like us on social media!")])]),_c('v-list',{attrs:{"subheader":"","light":""}},[_c('v-divider',{attrs:{"inset":""}}),_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Social Media Milestones "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-comment-text-multiple")])],1),_vm._l(([
        {
          text: 'Click here to write a review, to help us get 20 great reviews ',
          href: 'https://play.google.com/store/apps/details?id=com.WebbTechSweden.OinkWeb',
          strongText: 'on Google Play',
          icon: 'mdi-google-play',
          target: '_blank',
        },
        {
          text: 'Click here to write a review, to help us get 20 great reviews on ',
          href: 'https://www.facebook.com/OinkWe/reviews/',
          strongText: 'our Facebook page',
          icon: 'mdi-facebook',
          target: '_blank',
        },
        {
          text: 'Click here to sign up to our newsletter, to help us get 100 subscribers on',
          href: '#get-newsletter',
          strongText: 'our mailing list',
          icon: 'mdi-email-outline',
        } ]),function(milestone){return _c('v-list-item',{key:milestone.text,attrs:{"href":milestone.href,"target":milestone.target}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-flag-triangle")])],1),_c('v-list-item-content',{attrs:{"href":"milestone.href"}},[_c('v-list-item-title',[_vm._v(_vm._s(milestone.text)+" "),_c('strong',[_vm._v(_vm._s(milestone.strongText))])])],1),_c('v-icon',[_vm._v(_vm._s(milestone.icon))])],1)}),_c('v-divider',{attrs:{"inset":""}}),_c('v-subheader',{attrs:{"inset":""}},[_vm._v("App & Books Roadmap "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-road")])],1),_vm._l(([
        { text: 'Write the first book series' },
        { text: 'Make the books available for printing' },
        { text: 'Make the app read the books out loud' },
        { text: 'Translate the books to many languages' } ]),function(milestone){return _c('v-list-item',{key:milestone.text},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-flag-triangle")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(milestone.text)}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }