var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"subheader":"","color":"transparent"}},[_c('v-divider'),_c('v-subheader',[_vm._v("Available languages")]),_vm._l(([
    {
      text: 'English',
      flag: 'us',
    },
    {
      text: 'Svenska (Swedish)',
      flag: 'se',
    } ]),function(lang){return _c('v-list-item',{key:lang.text},[_c('v-list-item-icon',[_c('span',{staticClass:"fi",class:'fi-' + lang.flag})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(lang.text)+" ")])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }