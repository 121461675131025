<template>
  <v-card max-width="600" class="mx-auto my-16" color="orange" id="get-app">
    <v-card-title class="justify-center pb-0">
      <v-icon x-large>mdi-tablet-cellphone</v-icon>
    </v-card-title>
    <v-card-title class="justify-center pt-0">{{
    t[lang].freeAppHeader
}}</v-card-title>
    <v-card-text style="color: black; background-color: white; padding-top: 16px">
      <p>{{ t[lang].oinkwebIs }}</p>
      <p>{{ t[lang].expCozy }}</p>
      <p>
        <strong>{{ t[lang].turnSoundOn }}</strong>
      </p>

      <LangAvailableApp />
    </v-card-text>

    <v-btn v-if="isAndroid()" color="orange" block dark x-large
      href="https://play.google.com/store/apps/details?id=com.WebbTechSweden.OinkWeb" target="_blank">{{
    t[lang].downloadBtn
}}</v-btn>
    <v-btn v-else-if="screenOrientation == '90' || screenOrientation == 'landscape-primary'" color="orange" block dark
      x-large @click="if (unityFinishedLoading) unityShow = true;" class="
        animate__animated animate__pulse animate__infinite animate__delay-2s
      ">
      <v-progress-circular v-if="!unityFinishedLoading" indeterminate color="white" class="mr-8"></v-progress-circular>
      {{
    unityFinishedLoading ? t[lang].startBtnStart : t[lang].startBtnLoading
}}
      {{ unityFinishedLoading ? "" : unityProgress + "%" }}
    </v-btn>
    <v-btn v-else color="red">
      Please turn your device to landscape mode to run the app!
    </v-btn>

    <div id="unity-container" class="unity-desktop" style="
        position: fixed;
        top: 0px;
        left: 0px;
        transform: none;
        background-color: white;
        z-index: 999999;
      " :style="{
  height: height + 'px',
  left: (unityShow ? 0 : -width * 2) + 'px',
}">
      <v-icon v-if="unityShow" @click="unityShow = false" x-large style="position: fixed; top: 0px; right: 0px">
        mdi-close-thick</v-icon>
      <canvas id="unity-canvas" style="margin-top: 40px"></canvas>
      <div id="unity-loading-bar">
        <div id="unity-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
        </div>
      </div>
      <div id="unity-warning" zstyle="height: 0"></div>
      <div id="unity-footer" style="visibility: hidden">
        <div id="zz-unity-webgl-logo"></div>
        <div id="unity-fullscreen-button"></div>
        <div id="unity-build-title">
          OinkWeb
          <!-- width: {{ width / 2 }}, height: {{ height / 2 }} -->
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import LangAvailableApp from "./LangAvailableApp";

export default {
  name: "AppGet",
  components: {
    LangAvailableApp,
  },
  data() {
    return {
      width: screen.width,
      height: screen.height,
      screenOrientation: window.screen.orientation ? window.screen.orientation.type : window.orientation,
      unityFinishedLoading: false,
      unityShow: false,
      unityProgress: 0,

      lang: localStorage.getItem("lang"),
      t: {
        en: {
          freeAppHeader: "Free App — Interactive Book",
          oinkwebIs: "OinkWeb is a story app made for 3- to 6-year-olds.",
          expCozy:
            "Experience cozy moments in our fantasy world (that develop your child's creativity). Made by educators, artists and musicians. Interact with imaginative characters, engaging questions and music! Works on most tablets, mobiles and computers.",
          turnSoundOn: "Turn your sound ON!",
          startBtnLoading: "Loading app... ",
          startBtnStart: "START",
          downloadBtn: "Download",
        },
        sv: {
          freeAppHeader: "Gratis App — Interaktiv Bok",
          oinkwebIs: "OinkWeb är en sago-app för 3- till 6-åringar.",
          expCozy:
            "För mysiga stunder i vår fantasivärld (som utvecklar ditt barns kreativitet). Skapad av pedagoger, konstnärer och musiker. Interagera med fantasifulla figurer, engagerande frågor och musik! Fungerar på de flesta surfplattor, mobiler och datorer.",
          turnSoundOn: "OBS! Sätt på ljudet!",
          startBtnLoading: "Laddar appen... ",
          startBtnStart: "STARTA",
          downloadBtn: "Ladda ned",
        },
      },
    };
  },

  methods: {
    isAndroid() {
      if (/Android/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },

    /*
    handleResize() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      console.log(
        "resize triggered",
        "width:",
        this.width,
        "height:",
        this.height
      );
    },
    */

    unityUpdateProgress(progress) {
      //this.unityProgress = parseInt(progress * 100 * 100) / 100; // parse with 2 decimals
      this.unityProgress = parseFloat(progress * 100).toFixed(2); // parse with 2 decimals
    },

    unityDoComplete() {
      this.unityFinishedLoading = true;
    },


    handleOrientationChange() {
      this.screenOrientation = window.screen.orientation ? window.screen.orientation.type : window.orientation;
      //alert("orientation change");
    }

  },

  created() {
    //window.addEventListener("resize", this.handleResize);

    // make unity (classic js) be able to access vue
    window.unityDoComplete = this.unityDoComplete;

    window.addEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
  },

  /*watch: {

    // not needed
    isLandscape(wasLandscape, isLandscape) {
      this.txt = `isLandscape changed from ${wasLandscape} to ${isLandscape}`;
    },

  },
  */
};
</script>
