<template>
  <v-card
    max-width="600"
    class="mx-auto my-16 py-16"
    color="light-green accent-4"
    id="get-newsletter"
    href="/"
  >
    <v-card-title class="justify-center pt-0"
      >OinkWeb.com - A child learning platform</v-card-title
    >
    <v-img
      :src="require('../assets/ui/oinkweb-qr-231x231.png')"
      class="mx-auto"
      contain
      max-width="231"
      transition="scale-transition"
    />
    <v-card-text class="text-center">
      <p>Last updated: {{ lastUpdated }}</p>
      <p>
        Powered by
        <a href="https://webbtech.nu" target="_blank" rel="nofollow noopener">
          <v-img
            alt="Webb Tech Sweden Logo"
            class="mx-auto"
            contain
            :src="require('../assets/webb-tech-sweden-logo-143x25.png')"
            transition="scale-transition"
            width="143"
        /></a></p
    ></v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      lang: localStorage.getItem("lang"),
      lastUpdated: document.lastModified,
    };
  },
};
</script>

