import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

// before Vue
const browserLang = navigator.language.slice(0, 2);
// set default language to sv if browser is sv
if (localStorage.getItem("lang") === null && browserLang == "sv") localStorage.setItem("lang", "sv");
else if (localStorage.getItem("lang") === null) localStorage.setItem("lang", "en");

Vue.config.productionTip = false

window.vm = new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')

/*
export {
  vm
}
*/

// https://stackoverflow.com/questions/71403278/how-can-i-access-mutate-vue-component-properties-from-vanilla-js
// https://forum.vuejs.org/t/simple-call-to-vue-function-in-methods-from-external-javascript-file/79064
// https://www.google.com/search?q=vue+instance+extenral+js&oq=vue+instance+extenral+js&aqs=chrome..69i57j69i64.6927j0j1&sourceid=chrome&ie=UTF-8
// https://stackoverflow.com/questions/66008537/how-do-i-call-a-vue-js-function-from-an-external-javascript-file